import { Breadcrumb, SimpleCard } from "@gull";
import Spin from "@gull/components/Spin";
import { getCurrentFinancialYear } from "@utils";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  GenereateCode,
  fetchQuotations,
  createQuotation,
  updateQuoation,
  fetchQuotationsTerms,
  createQuotationsTerms,
} from "../../../redux/actions/QuotationActions";
import { fetchCustomersList } from "../../../redux/actions/CustomerActions";
import {
  fetchProductWithFiltersV2,
  fetchProductBrandCodeList,
} from "../../../redux/actions/ProductActions";
import { Formik } from "formik";
import * as yup from "yup";
import AsyncSelect from "react-select/async";
import QuotationTable from "./component/QuotationTable";
import { debounce, uniqueId } from "lodash";
import { Button, Form } from "react-bootstrap";
import AddCustomer from "../admin-customer/modals/CreateCustomer";
import { NotificationManager } from "react-notifications";

const QuotationFormSchema = yup.object().shape({
  code: yup.string().required("Code is required"),
  project_name: yup.string().required("Project Name is required"),
  party_id: yup.string().required("customer is required"),
  termsList: yup.array().min(1, "atleast one term needs to selected"),
  kind_attend: yup.string().nullable(true),
  contact_number: yup.string().nullable(true),
});

class QuotationForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quotationId: this.props?.match?.params?.quotationId,
      formLoading: false,
      generatedCode: null,
      formData: {
        code:
          !!this.props?.location?.state?.data &&
          !!this.props?.location?.state?.codeType &&
          this.props?.location?.state?.codeType !== "COPY"
            ? "loading..."
            : `NI/QA/${getCurrentFinancialYear()}/${moment()
                .valueOf()
                .toString()
                .slice(-5)}`,
        party_id: undefined,
        project_name: undefined,
        kind_attend: null,
        contact_number: null,
        termsList: [],
      },
      is_manifold: false,
      selectedManifold: "",
      searchedProjectCode: "",
      productList: [],
      manifoldProductList: [],
      productBrandCodeMapper: {},
      searchedProducts: [],
      isNewBrandCodeAdd: true,
      replaceBrandCode: "",
      openCustomerModal: false,
      quotationsTermsList: [],
      customTermValue: "",
    };
    if (this.props?.location?.state?.data) {
      const that = this;
      let code = this.props?.location?.state?.data?.code;
      if (code?.includes("-R")) {
        code = code.slice(0, code.lastIndexOf("-R"));
      }
      if (code?.includes("-O")) {
        code = code.slice(0, code.lastIndexOf("-O"));
      }
      if (
        !!this.props?.location?.state?.codeType &&
        this.props?.location?.state?.codeType !== "COPY"
      ) {
        this.props
          .GenereateCode({
            orignalCode: code,
            codeType: !!this.props?.location?.state?.codeType
              ? this.props?.location?.state?.codeType ?? "REVISE"
              : "",
          })
          .then((resp) => {
            that.setState({
              formData: {
                ...that.state.formData,
                code: resp?.data?.data?.code,
              },
              generatedCode: resp?.data?.data?.code,
            });
          });
      }
      this.props.fetchQuotations({
        id: window.btoa(this.props?.location?.state?.data?.id),
      });
    }
  }

  componentDidMount() {
    this.props.fetchCustomersList();
    this.props.fetchQuotationsTerms().then((resp) => {
      this.setState({
        quotationsTermsList: resp,
        generatedCode:
          this.props?.location?.state?.codeType === "COPY"
            ? this.state.formData?.code
            : this.state.generatedCode,
        // formData: {
        //   ...this.state.formData,
        //   termsList: resp?.map((i) => i?.id),
        // },
      });
    });
    if (this.state.quotationId) {
      this.props.fetchQuotations({ id: this.state.quotationId });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.quotationData !== prevProps.quotationData) {
      const { quotationData } = this.props;
      this.proccessQuotationData(quotationData);
    }
    if (this.props.productItems !== prevProps?.productItems) {
      const { productBrandCodeMapper, isNewBrandCodeAdd, is_manifold } =
        this.state;
      // this code was added to fix the deactivated product items were showing up
      let item = {
        ...(this.props.productItems?.[0] ?? {}),
        productItems: (
          this.props.productItems?.[0] ?? { productItems: [] }
        )?.productItems?.filter((i) => !!i?.status),
      };
      this.setState({
        productBrandCodeMapper: {
          ...productBrandCodeMapper,
          [item?.brand_code]: item?.productItems,
        },
      });
      if (is_manifold) {
        if (isNewBrandCodeAdd) {
          this.addManifoldProduct(item);
        }
      } else {
        if (isNewBrandCodeAdd) {
          this.addProduct(item);
        } else {
          this.updateExitingProduct(item);
        }
      }
    }
  }

  updateExitingProduct(item) {
    const { productList } = this.state;
    this.setState(
      {
        productList: productList?.map((product, index) => {
          let tempProduct = product;
          const newProduct = item;
          const replaceBrandCode = this.state?.replaceBrandCode?.split(":");
          if (
            tempProduct?.brand_code === replaceBrandCode[0] &&
            index === Number(replaceBrandCode[1])
          ) {
            tempProduct = {
              ...product,
              ...newProduct,
              productItems: newProduct?.productItems
                ?.filter((newProdItem) => {
                  const foundItem = !!tempProduct?.productItems?.find(
                    (pItem) =>
                      pItem?.product_item?.size?.match(/(\d+)/)[0] ===
                      newProdItem?.size?.match(/(\d+)/)[0]
                  );
                  return foundItem;
                })
                ?.map((newProdItem) => {
                  const oldProdcutItem = tempProduct?.productItems?.find(
                    (pItem) => pItem?.product_item?.size === newProdItem?.size
                  );
                  const price = Math.round(Number(newProdItem?.price ?? "0"));
                  const qty = Number(
                    oldProdcutItem?.quantity ?? newProdItem?.quantity ?? "1"
                  );
                  const discount = Number(newProdItem?.sales_discount ?? "0");
                  const pdiscount = Number(
                    newProdItem?.purchase_discount ?? "0"
                  );
                  oldProdcutItem["product_item"] = newProdItem;
                  oldProdcutItem["product"] = item;
                  oldProdcutItem["product_id"] = item?.id;
                  oldProdcutItem["product_item_id"] = newProdItem?.id;
                  const modifiedProductInfo = oldProdcutItem;
                  console.log(modifiedProductInfo);
                  return {
                    ...modifiedProductInfo,
                    ...this.priceCalcuations({
                      quotation_price: price,
                      quantity: qty,
                      sales_discount: discount,
                      purchase_discount: pdiscount,
                    }),
                  };
                }),
            };
          }
          return {
            ...tempProduct,
          };
        }),
      },
      () => {
        console.log("productItems", this.state.productList);
      }
    );
  }

  priceCalcuations(productData) {
    const quotation_price = Math.round(
      Number(productData?.price ?? productData?.quotation_price ?? "0")
    );
    const quantity = Number(
      productData?.quotation_product?.quantity ?? productData?.quantity ?? "1"
    );
    const discount = Number(
      productData?.quotation_product?.sales_discount ??
        productData?.sales_discount ??
        "0"
    );
    const pdiscount = Number(
      productData?.quotation_product?.purchase_discount ??
        productData?.purchase_discount ??
        "0"
    );
    const sales_net_amount = Math.round(
      (quotation_price - (quotation_price * discount) / 100).toFixed(2)
    );
    const purchase_net_amount = Math.round(
      (quotation_price - (quotation_price * pdiscount) / 100).toFixed(2)
    );
    return {
      price: quotation_price,
      quotation_price,
      quantity,
      sales_discount: discount,
      sales_net_amount: sales_net_amount,
      sales_total_amount: Math.round((sales_net_amount * quantity).toFixed(2)),
      purchase_discount: pdiscount,
      purchase_net_amount: Math.round(
        (quotation_price - (quotation_price * pdiscount) / 100).toFixed(2)
      ),
      purchase_total_amount: Math.round(
        (purchase_net_amount * quantity).toFixed(2)
      ),
    };
  }

  productItemDataTransformer() {
    return {
      id: "",
      quotation_id: "",
      quotation_product_id: "",
      product_id: "",
      product_item_id: "",
      quantity: "",
      quotation_price: "",
      sales_net_amount: "",
      sales_total_amount: "",
      sales_discount: "",
      purchase_net_amount: "",
      purchase_total_amount: "",
      purchase_discount: "",
      is_manifold: "",
      manifold_desc: "",
      status: "",
      updated_by: "",
      updated_at: "",
    };
  }

  quotationProductDataTransformer(products, is_manifold) {
    if (!Array.isArray(products)) {
      return;
    }

    return products?.map((product) => {
      return {
        ...product,
        is_manifold: is_manifold,
        action: "update",
        productItems: product?.productItems?.map((newProdItem) => {
          return {
            action: "update",
            ...newProdItem,
            is_manifold,
            ...this.priceCalcuations(newProdItem),
          };
        }),
      };
    });
  }

  proccessQuotationData(quotationData) {
    let quotation = {};
    if (quotationData?.length) {
      quotation = quotationData[0];
      this.setState({
        productBrandCodeMapper: {
          ...this.state.productBrandCodeMapper,
          ...quotation?.brandCodeMapper,
        },
      });
      if (quotation?.id) {
        console.log(quotation.products);
        const entireList = [...quotation?.products, ...quotation?.manifolds];
        const manifoldList = [];
        const productList = [];
        for (let index = 0; index < entireList?.length; index++) {
          const quotationItems = entireList[index];
          if (quotationItems?.productItems?.[0]?.is_manifold === 1) {
            manifoldList.push({
              ...quotationItems,
              is_manifold: true,
              ...this.priceCalcuations(quotationItems),
              purchase_net_amount: Math.round(
                Number(quotationItems.purchase_net_amount).toFixed(2)
              ),
              purchase_total_amount: Math.round(
                Number(quotationItems.purchase_total_amount).toFixed(2)
              ),
              productItems: quotationItems?.productItems?.map((item) => ({
                ...item,
                is_manifold: true,
              })),
            });
          } else {
            productList.push({
              ...quotationItems,
              group_by: quotationItems?.productItems?.[0]?.group_by,
              comment: quotationItems?.productItems?.[0]?.comment,
            });
          }
        }
        this.setState({
          quotation,
          productList: this.quotationProductDataTransformer(productList, false),
          manifoldProductList: this.quotationProductDataTransformer(
            manifoldList,
            true
          ),
          generatedCode:
            this.props?.location?.state?.codeType === "COPY"
              ? this.state.formData?.code
              : this.state.generatedCode,
          formData: {
            ...this.state.formData,
            code:
              this.props?.location?.state?.codeType === "COPY"
                ? this.state.formData?.code
                : this.state.generatedCode ?? quotation?.code,
            party_id: quotation.party_id ? Number(quotation.party_id) : "",
            project_name: quotation.project_name ? quotation.project_name : "",
            termsList: Array.from(
              new Set(quotation?.quotation_terms?.map((item) => item?.id))
            ),
            kind_attend: quotation?.kind_attend ?? null,
            contact_number: quotation?.contact_number ?? null,
          },
        });
      }
    }
  }

  findProductItemByCode(brandCode, code) {
    return this.state.productBrandCodeMapper[brandCode]?.find(
      (item) => item.code === code
    );
  }

  handleProjectItemCalculations = (
    is_manifold,
    productIndex,
    productItemIndex,
    code,
    key,
    value
  ) => {
    const updatedProductList = this.state?.[
      is_manifold ? "manifoldProductList" : "productList"
    ]?.map((quotationItem, index) => {
      if (index === productIndex) {
        return {
          ...quotationItem,
          ...(code
            ? {
                productItems: quotationItem?.productItems?.map(
                  (item, itemIndex) => {
                    if (
                      item?.product_item?.code === code &&
                      itemIndex === productItemIndex
                    ) {
                      const price = item?.price;
                      const qty = key === "quantity" ? value : item?.quantity;
                      const discount =
                        key === "sales_discount" ? value : item?.sales_discount;
                      const pdiscount =
                        key === "purchase_discount"
                          ? value
                          : item?.purchase_discount;
                      return {
                        ...item,
                        ...this.priceCalcuations({
                          quotation_price: price,
                          quantity: qty,
                          sales_discount: discount,
                          purchase_discount: pdiscount,
                        }),
                      };
                    }
                    return item;
                  }
                ),
              }
            : { [key]: value }),
        };
      }
      return {
        ...quotationItem,
      };
    });
    this.setState(
      {
        [is_manifold ? "manifoldProductList" : "productList"]:
          updatedProductList,
      },
      () => {
        if (is_manifold) {
          this.handleManifoldCalculations(productIndex, "", "");
        }
      }
    );
  };

  handleManifoldCalculations = (productIndex, key, value) => {
    const updatedProductList = this.state?.["manifoldProductList"]?.map(
      (quotationItem, index) => {
        if (index === productIndex) {
          const qty = key === "quantity" ? value : quotationItem?.quantity;
          const discount =
            key === "sales_discount" ? value : quotationItem?.sales_discount;
          const pdiscount =
            key === "purchase_discount"
              ? value
              : quotationItem?.purchase_discount;
          const description =
            key === "manifold_desc" ? value : quotationItem?.manifold_desc;

          const totalStats = {
            price: 0,
            pprice: 0,
          };

          if (!!quotationItem?.productItems?.length) {
            const nouse = quotationItem?.productItems?.map((item) => {
              totalStats.price += Number(item.sales_total_amount);
              totalStats.pprice += Number(item.purchase_total_amount);
              return item;
            });
            console.log(nouse);
          }
          return {
            ...quotationItem,
            manifold_desc: description,
            ...totalStats,
            ...this.priceCalcuations({
              quotation_price: totalStats.price,
              quantity: qty,
              sales_discount: discount,
              purchase_discount: pdiscount,
            }),
            purchase_net_amount: (
              totalStats.pprice -
              totalStats.pprice * (pdiscount / 100)
            ).toFixed(2),
            purchase_total_amount: (
              (totalStats.pprice - totalStats.pprice * (pdiscount / 100)) *
              qty
            ).toFixed(2),
          };
        }
        return {
          ...quotationItem,
        };
      }
    );
    this.setState({
      manifoldProductList: updatedProductList,
    });
  };

  addManifoldProduct(item) {
    const { manifoldProductList, is_manifold, selectedManifold, quotation } =
      this.state;

    const newProdItem = item?.productItems[0];
    const newProdItemId = uniqueId();

    const price = Number(newProdItem?.price ?? "0");
    const qty = Number(newProdItem?.quantity ?? "1");
    const discount = Number(newProdItem?.sales_discount ?? "0");
    const pdiscount = Number(newProdItem?.purchase_discount ?? "0");

    const manifoldItem = {
      is_manifold,
      group_by: moment().valueOf(),
      product: item,
      product_item: newProdItem,
      ...this.priceCalcuations({
        quotation_price: price,
        quantity: qty,
        sales_discount: discount,
        purchase_discount: pdiscount,
      }),
    };

    const description = `Manifold ${manifoldProductList?.length + 1}`;

    let newManifoldProductList = [];

    if (manifoldProductList?.length && !!selectedManifold) {
      newManifoldProductList = manifoldProductList?.map((quotationItem) => {
        let updatedQuotationItem = quotationItem;
        if (Number(quotationItem?.id) === Number(selectedManifold)) {
          updatedQuotationItem = {
            ...quotationItem,
            productItems: [
              ...quotationItem?.productItems,
              {
                ...quotationItem?.productItems?.[0],
                ...manifoldItem,
                action: "create",
                group_by: quotationItem?.productItems?.[0]?.group_by,
              },
            ],
          };
        }
        let mPrice = 0;
        let mPPrice = 0;
        const nouse = updatedQuotationItem?.productItems?.map((item) => {
          mPrice += Number(item.sales_total_amount);
          mPPrice += Number(item.purchase_total_amount);
          return item;
        });
        console.log(nouse);
        const finalQuotationItem = {
          action: "create",
          ...updatedQuotationItem,
          ...this.priceCalcuations({
            quotation_price: mPrice,
            quantity: updatedQuotationItem?.quantity,
            sales_discount: updatedQuotationItem.sales_discount,
            purchase_discount: updatedQuotationItem?.purchase_discount,
          }),
          purchase_net_amount: (
            mPPrice -
            mPPrice * (updatedQuotationItem?.purchase_discount / 100)
          ).toFixed(2),
          purchase_total_amount: (
            (mPPrice -
              mPPrice * (updatedQuotationItem?.purchase_discount / 100)) *
            updatedQuotationItem?.quantity
          ).toFixed(2),
        };
        return finalQuotationItem;
      });
    } else {
      const mPrice = (
        (manifoldItem?.price - manifoldItem?.price * (discount / 100)) *
        qty
      )?.toFixed(2);
      const finalQuotationItem = {
        action: "create",
        id: newProdItemId,
        quotation_id: quotation?.id ?? null,
        manifold_desc: description,
        is_manifold,
        group_by: moment().valueOf(),
        ...this.priceCalcuations({
          quotation_price: mPrice,
          quantity: qty,
          sales_discount: 0,
          purchase_discount: 0,
        }),
        purchase_net_amount: Number(
          manifoldItem?.purchase_total_amount
        )?.toFixed(2),
        purchase_total_amount: (
          Number(manifoldItem?.purchase_total_amount) * qty
        ).toFixed(2),
        productItems: [{ ...manifoldItem, action: "create" }],
      };
      newManifoldProductList = [...manifoldProductList, finalQuotationItem];
    }

    let data = [...newManifoldProductList];

    this.setState({
      manifoldProductList: data,
      selectedManifold: !selectedManifold ? newProdItemId : selectedManifold,
    });
  }

  addProduct(item) {
    const { productList, is_manifold } = this.state;

    let newProduct = [];
    newProduct.push({
      ...item,
      action: "create",
      is_manifold,
      group_by: moment().valueOf(),
      productItems: [],
    });

    let data = [...productList, ...newProduct];

    this.setState({
      productList: data,
    });
  }

  addProductItem(group_by, brandCode, code) {
    const { productList } = this.state;
    this.setState(
      {
        productList: [...productList]?.map((item) => {
          if (brandCode === item?.brand_code && group_by === item?.group_by) {
            const newProdcutItem = this.findProductItemByCode(
              item?.brand_code,
              code
            );
            const price = Number(newProdcutItem?.price ?? "0");
            const qty = Number(newProdcutItem?.quantity ?? "1");
            const discount = Number(newProdcutItem?.sales_discount ?? "0");
            const pdiscount = Number(newProdcutItem?.purchase_discount ?? "0");

            const newItemData = {
              product: item,
              product_item: newProdcutItem,
              is_manifold: false,
              group_by: item?.group_by,
              // product: item,
              action: "create",
              ...this.priceCalcuations({
                quotation_price: price,
                quantity: qty,
                sales_discount: discount,
                purchase_discount: pdiscount,
              }),
            };
            return {
              ...item,
              productItems: [...item.productItems, newItemData],
            };
          } else {
            return {
              ...item,
            };
          }
        }),
      },
      () => {
        this.setState({
          is_manifold: false,
        });
        console.log("productItems", this.state.productList);
      }
    );
  }

  removeProductItem(removeItem, productItemIndex, productIndex) {
    const { manifoldProductList, productList } = this.state;
    let filteredProductList = (
      removeItem.is_manifold ? manifoldProductList : productList
    )?.map((quotationItem, index) => {
      if (productIndex !== index) {
        return quotationItem;
      }

      let mPrice = 0;
      // let mPPrice = 0;
      let productItems = [];
      if (!!this.state.quotationId) {
        productItems = quotationItem?.productItems?.map((item, index) => {
          if (
            !!(
              productItemIndex === index &&
              item?.product_item.code === removeItem?.product_item?.code
            )
          ) {
            return {
              ...item,
              action: item?.action === "create" ? "drop" : "delete",
            };
          } else {
            return item;
          }
        });
      } else {
        productItems = quotationItem?.productItems?.filter((item, index) => {
          return !(
            productItemIndex === index &&
            item?.product_item.code === removeItem?.product_item?.code
          );
        });
      }
      productItems = productItems
        ?.filter((item) => item?.action !== "drop")
        .map((item) => {
          if (item?.action !== "delete")
            mPrice += Number(item.sales_total_amount);
          // mPPrice += Number(item.purchase_total_amount);
          return item;
        });
      return {
        ...quotationItem,
        action: !productItems?.filter((item) => item?.action !== "delete")
          ?.length
          ? quotationItem?.action === "create"
            ? "drop"
            : "delete"
          : quotationItem?.action,
        productItems,
        ...this.priceCalcuations({
          quotation_price: mPrice,
          quantity: quotationItem?.quantity,
          sales_discount: quotationItem.sales_discount,
          purchase_discount: quotationItem?.purchase_discount,
        }),
      };
    });
    if (!this.state.quotationId) {
      filteredProductList = filteredProductList.filter((prod) => {
        return removeItem.is_manifold || removeItem?.product_id === prod?.id
          ? !!prod?.productItems?.length
          : true;
      });
    }

    this.setState({
      [removeItem.is_manifold ? "manifoldProductList" : "productList"]:
        filteredProductList.filter((item) => item?.action !== "drop"),
    });
  }

  asyncSelectLoadOptions = debounce((inputValue, cb) => {
    const { is_manifold } = this.state;
    if (!inputValue) {
      cb([]);
      return false;
    }
    this.props
      .fetchProductBrandCodeList({
        brand_code: inputValue,
        manifold: is_manifold,
      })
      .then((resp) => {
        cb(
          !!inputValue
            ? resp?.data?.data?.map((item) => ({
                label: item?.brand_code,
                value: item?.brand_code,
              })) || []
            : []
        );
      });
  }, 300);

  asyncOnChange(selectedOption, action, isNew = false) {
    if (action?.action === "select-option") {
      if (!!selectedOption?.value && !!selectedOption?.value?.length) {
        const { is_manifold } = this.state;
        this.setState(
          {
            isNewBrandCodeAdd: isNew,
          },
          () => {
            this.props.fetchProductWithFiltersV2({
              [is_manifold ? "code" : "brand_code"]: selectedOption?.value,
            });
          }
        );
      }
    }
  }

  handleFormValueChange = (e) => {
    let formData = {
      ...this.state.formData,
      [e.target.name]: e.target.value,
    };
    if (e.target.name === "party_id") {
      const party_info = this.props?.customerList?.find(
        (customer) => customer?.id === Number(e.target.value)
      );
      formData = {
        ...formData,
        kind_attend: party_info?.contacted_by ?? null,
        contact_number: party_info?.phone ?? null,
      };
    }
    this.setState({
      formData,
    });
  };

  handleSubmit = (value, { isSubmitting }) => {
    const that = this;
    this.setState(
      {
        formLoading: true,
      },
      () => {
        const productItems = [
          ...Object.values(this.state.productList)
            .flat()
            ?.map((prod, index) => ({
              ...prod,
            })),
          ...Object.values(this.state.manifoldProductList)
            .flat()
            ?.map((prod, index) => ({
              ...prod,
            })),
        ];

        const payload = {
          ...that.state.quotation,
          ...that.state.formData,
          ...value,
          productItems,
        };
        const apiCall = !!that.state.quotationId
          ? that.props.updateQuoation
          : that.props.createQuotation;

        apiCall(payload)
          .then((resp) => {
            if (resp) {
              that.setState(
                {
                  formLoading: false,
                },
                () => {
                  that.props.history.push("/quotations");
                }
              );
            } else {
              that.setState({
                formLoading: false,
              });
            }
          })
          .catch((e) => {
            console.error(e);
            that.setState({
              formLoading: false,
            });
          });
      }
    );
  };

  render() {
    const { loading, customerLoading, customerList } = this.props;
    const {
      formLoading,
      quotationId,
      productList,
      manifoldProductList,
      productBrandCodeMapper,
      searchedProducts,
      formData,
      is_manifold,
      selectedManifold,
      openCustomerModal,
      quotationsTermsList,
      customTermValue,
    } = this.state;
    return (
      <div className="quotation-form">
        <Breadcrumb
          routeSegments={[
            {
              name: `${quotationId ? "Update" : "Create"} ${
                !!this.props?.location?.state?.codeType &&
                this.props?.location?.state?.codeType !== "COPY"
                  ? this.props?.location?.state?.codeType === "REVISE"
                    ? "Revise"
                    : "Option"
                  : ""
              } Quotation`,
              path: "/",
            },
          ]}
          isBackButton={true}
        />
        <Spin loading={loading | formLoading}>
          <Formik
            enableReinitialize
            initialValues={formData}
            validationSchema={QuotationFormSchema}
            onSubmit={this.handleSubmit}
            handleChange={this.handleFormValueChange}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <form
                  onSubmit={(e) => {
                    console.log(errors);
                    handleSubmit(e);
                  }}
                >
                  <div className="row mb-5">
                    <div className="col-md-12">
                      <SimpleCard title="" className="mb-3">
                        <div className="row">
                          <div className="col-md-4">
                            <b>Quotation Code</b>
                            <div className="form-group">
                              <input
                                className="form-control position-relative"
                                type="text"
                                name="code"
                                onChange={(e) => {
                                  this.handleFormValueChange(e);
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.code}
                                placeholder="code"
                                readOnly
                              />
                              {errors.code && touched.code && (
                                <div className="text-danger mt-1 ml-1">
                                  {errors.code}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <b>Customer Name</b>
                            <div className="form-group">
                              <div
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                }}
                              >
                                <select
                                  id="picker1"
                                  className="form-control"
                                  name="party_id"
                                  onChange={(e) => {
                                    this.handleFormValueChange(e);
                                    handleChange(e);
                                  }}
                                  style={{
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                  }}
                                  disabled={customerLoading}
                                  value={values.party_id}
                                >
                                  {!!customerLoading ? (
                                    <option value="">Loading...</option>
                                  ) : (
                                    <option value="">Select Customer</option>
                                  )}
                                  {!customerLoading &&
                                    !!customerList?.length &&
                                    customerList?.map((cust) => {
                                      return (
                                        <option value={cust?.id}>
                                          {cust?.name}
                                        </option>
                                      );
                                    })}
                                </select>
                                <Button
                                  style={{
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      openCustomerModal: true,
                                    });
                                  }}
                                >
                                  Add
                                </Button>
                              </div>
                              {errors.party_id && touched.party_id && (
                                <div className="text-danger mt-1 ml-1">
                                  {errors.party_id}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <b>Project Name</b>
                            <div className="form-group">
                              <input
                                className="form-control position-relative"
                                type="text"
                                name="project_name"
                                onChange={(e) => {
                                  this.handleFormValueChange(e);
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.project_name}
                                placeholder="Project Name"
                              />
                              {errors.project_name && touched.project_name && (
                                <div className="text-danger mt-1 ml-1">
                                  {errors.project_name}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <b>Kind Attend.</b>
                            <div className="form-group">
                              <input
                                className="form-control position-relative"
                                type="text"
                                name="kind_attend"
                                onChange={(e) => {
                                  this.handleFormValueChange(e);
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.kind_attend}
                                placeholder="kind attend."
                              />
                              {errors.kind_attend && touched.kind_attend && (
                                <div className="text-danger mt-1 ml-1">
                                  {errors.kind_attend}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <b>Contact Number</b>
                            <div className="form-group">
                              <input
                                className="form-control position-relative"
                                type="text"
                                name="contact_number"
                                onChange={(e) => {
                                  this.handleFormValueChange(e);
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.contact_number}
                                placeholder="Contact Number"
                              />
                              {errors.contact_number &&
                                touched.contact_number && (
                                  <div className="text-danger mt-1 ml-1">
                                    {errors.contact_number}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mt-3">
                            <QuotationTable
                              {...{
                                totalCountProductList: productList?.length,
                                productList: !!quotationId
                                  ? productList?.filter(
                                      (prod) => prod?.action !== "drop"
                                    )
                                  : productList,
                                totalCountManifoldProductList:
                                  manifoldProductList,
                                manifoldProductList: !!quotationId
                                  ? manifoldProductList?.filter(
                                      (prod) => prod?.action !== "drop"
                                    )
                                  : manifoldProductList,
                                productBrandCodeMapper,
                                addProductItem: this.addProductItem.bind(this),
                                removeProductItem:
                                  this.removeProductItem.bind(this),
                                handleProjectItemCalculations:
                                  this.handleProjectItemCalculations.bind(this),
                                handleManifoldCalculations:
                                  this.handleManifoldCalculations.bind(this),
                                asyncSelectLoadOptions:
                                  this.asyncSelectLoadOptions.bind(this),
                                asyncOnChange: this.asyncOnChange.bind(this),
                                setReplaceBrandCode: (brandCode) => {
                                  this.setState({
                                    replaceBrandCode: brandCode,
                                  });
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <b>{`Search Product ${
                              is_manifold ? "Item" : ""
                            } Code`}</b>
                            <AsyncSelect
                              styles={{
                                menu: (style) => ({
                                  ...style,
                                  zIndex: 99,
                                }),
                              }}
                              placeholder={`Search Product ${
                                is_manifold ? "Item" : ""
                              } Code`}
                              cacheOptions={false}
                              defaultOptions
                              value={searchedProducts}
                              onChange={(selectedOption, action) =>
                                this.asyncOnChange(selectedOption, action, true)
                              }
                              loadOptions={(inputValue, cb) =>
                                this.asyncSelectLoadOptions(inputValue, cb)
                              }
                            />
                          </div>
                          <div className="col-md-1">
                            <b> </b>
                            <div
                              className="form-group"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "end",
                                height: 58,
                              }}
                            >
                              <label className="checkbox checkbox-primary">
                                <input
                                  type="checkbox"
                                  name="manifold"
                                  onChange={(e) => {
                                    this.setState({
                                      is_manifold: !is_manifold,
                                    });
                                  }}
                                  disabled={customerLoading}
                                  checked={is_manifold}
                                />
                                <span style={{ fontSize: 14 }}>Manifold</span>
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                          {!!is_manifold && (
                            <div className="col-md-3">
                              <b>Select Manifold</b>
                              <div className="form-group">
                                <select
                                  id="picker1"
                                  className="form-control"
                                  name="Manifold"
                                  onChange={(e) => {
                                    this.setState({
                                      selectedManifold: e.target.value,
                                    });
                                  }}
                                  value={selectedManifold}
                                >
                                  <option value="">Create New Manifold</option>
                                  {manifoldProductList.map((item, index) => {
                                    return (
                                      <option value={item?.id}>
                                        {item?.manifold_desc}
                                      </option>
                                    );
                                  })}
                                </select>
                                {errors.party_id && touched.party_id && (
                                  <div className="text-danger mt-1 ml-1">
                                    {errors.party_id}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row">
                          {!!quotationsTermsList?.length && (
                            <div className="col-md-12">
                              <b>Terms & Conditions</b>
                              {errors.termsList && touched.termsList && (
                                <div className="text-danger mt-1 ml-1">
                                  {errors.termsList}
                                </div>
                              )}
                              <Form.Check // prettier-ignore
                                type={"checkbox"}
                                id={"all"}
                                label={
                                  quotationsTermsList?.length ===
                                  values?.termsList?.length
                                    ? "Deselect All"
                                    : "Select All"
                                }
                                checked={
                                  quotationsTermsList?.length ===
                                  values?.termsList?.length
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    "termsList",
                                    Array.from(
                                      new Set(
                                        quotationsTermsList?.length ===
                                        values?.termsList?.length
                                          ? []
                                          : quotationsTermsList?.map(
                                              (i) => i?.id
                                            )
                                      )
                                    )
                                  );
                                }}
                              />
                              {quotationsTermsList.map((item) => (
                                <Form.Check // prettier-ignore
                                  type={"checkbox"}
                                  id={item?.term}
                                  label={
                                    <div>
                                      {item?.term}{" "}
                                      {values?.termsList.includes(item?.id) && (
                                        <span class="badge badge-pill badge-danger">
                                          {values?.termsList?.indexOf(
                                            item?.id
                                          ) + 1}
                                        </span>
                                      )}
                                    </div>
                                  }
                                  checked={values?.termsList.includes(item?.id)}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "termsList",
                                      Array.from(
                                        new Set(
                                          values?.termsList.includes(item?.id)
                                            ? values?.termsList?.filter(
                                                (i) => i !== item?.id
                                              )
                                            : [...values?.termsList, item?.id]
                                        )
                                      )
                                    );
                                  }}
                                />
                              ))}
                              <div className="col-md-8">
                                <b>Custom Term</b>
                                <div className="form-group">
                                  <div
                                    style={{
                                      flexDirection: "row",
                                      display: "flex",
                                    }}
                                  >
                                    <input
                                      className="form-control position-relative"
                                      type="text"
                                      name="custom_term"
                                      onChange={(e) => {
                                        this.setState({
                                          customTermValue: e.target.value,
                                        });
                                      }}
                                      value={customTermValue}
                                      placeholder="Enter Custom Term Here"
                                    />
                                    <Button
                                      disabled={!customTermValue}
                                      style={{
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                      }}
                                      onClick={() => {
                                        if (!!customTermValue) {
                                          this.props
                                            .createQuotationsTerms({
                                              term: customTermValue,
                                            })
                                            .then((resp) => {
                                              this.setState(
                                                {
                                                  quotationsTermsList: [
                                                    ...quotationsTermsList,
                                                    ...resp,
                                                  ],
                                                },
                                                () => {
                                                  setFieldValue(
                                                    "termsList",
                                                    Array.from(
                                                      new Set([
                                                        ...values?.termsList,
                                                        resp?.[0]?.id,
                                                      ])
                                                    )
                                                  );
                                                }
                                              );
                                            });
                                        } else {
                                          NotificationManager.error(
                                            "term field cant be empty. Please check"
                                          );
                                        }
                                      }}
                                    >
                                      Create
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </SimpleCard>
                    </div>
                  </div>
                  <div className="sticky-footer">
                    <div className="container">
                      <Button
                        key={"primary3"}
                        variant={`primary`}
                        className="btn-rounded text-capitalize"
                        style={{ float: "right", width: 100 }}
                        type="submit"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Spin>
        <AddCustomer
          show={openCustomerModal}
          onClose={(customer) => {
            this.props.fetchCustomersList();
            this.setState({
              openCustomerModal: false,
              formData: {
                ...this.state.formData,
                party_id: customer?.id ?? "",
                kind_attend: customer?.contacted_by ?? "",
                contact_number: customer?.phone ?? "",
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productItems: state.product?.productDetails,
  quotationData: state.quotation?.data,
  loading: state.quotation.loading,
  quotation: state.quotation,
  customerList: state.customer?.data,
  customerLoading: state.customer?.loading,
});

const mapDispatchToProps = {
  GenereateCode,
  fetchQuotations,
  createQuotation,
  updateQuoation,
  fetchCustomersList,
  fetchProductWithFiltersV2,
  fetchProductBrandCodeList,
  fetchQuotationsTerms,
  createQuotationsTerms,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(QuotationForms));
